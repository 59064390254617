import React from 'react';
import { Link } from '@mui/material';
import { generateLink } from './utils';

const getContext = (text, start, end) => {
  const words = text.split(/\s+/); // Split by spaces
  let charCount = 0;
  let wordIndexStart = 0;
  let wordIndexEnd = 0;

  // Find the word index for the start and end positions
  for (let i = 0; i < words.length; i++) {
      const wordLength = words[i].length;
      if (charCount + wordLength >= start && wordIndexStart === 0) {
          wordIndexStart = i;
      }
      if (charCount + wordLength >= end) {
          wordIndexEnd = i;
          break;
      }
      charCount += wordLength + 1; // +1 for the space
  }

  // Get two words before and after the matched term
  const beforeWords = words.slice(Math.max(0, wordIndexStart - 2), wordIndexStart).join(' ');
  const afterWords = words.slice(wordIndexEnd + 1, wordIndexEnd + 3).join(' ');

  // Add ellipsis if necessary
  const beforeEllipsis = wordIndexStart > 2 ? '...' : '';
  const afterEllipsis = wordIndexEnd + 3 < words.length ? '...' : '';

  const term = text.slice(start, end);

  // Make sure the matched term is excluded from the before and after context
  return {
      before: beforeWords ? `${beforeEllipsis} ${beforeWords}`.trim() : '',
      term,
      after: afterWords ? `${afterWords} ${afterEllipsis}`.trim() : '',
  };
};



export const createColumns = (inputText) => [
    {
        header: 'Term',
        accessorKey: 'matched_term',
        Cell: ({ row }) => (
            <Link
                href={generateLink(row.original.iri, row.original.ontologyId)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {row.original.matched_term}
            </Link>
        ),
    },
    {
        header: 'Ontology ID',
        accessorKey: 'ontologyId',
        Cell: ({ row }) => (
            <span>
                {row.original.ontologyId}
            </span>
        ),
    },
    {
        header: 'Context',
        accessorKey: 'context',
        Cell: ({ row }) => {
            const { before, term, after } = getContext(
                inputText,
                row.original.start,
                row.original.end
            );
            return (
                <span>
                    <span style={{ color: 'grey' }}>{before} </span>
                    <strong>{term}</strong>
                    <span style={{ color: 'grey' }}> {after}</span>
                </span>
            );
        },
    },
    {
        header: 'IRI',
        accessorKey: 'iri',
        Cell: ({ row }) => (
            <Link
                href={row.original.iri}
                target="_blank"
                rel="noopener noreferrer"
            >
                {row.original.iri}
            </Link>
        ),
    },
    {
        header: 'Synonyms',
        accessorKey: 'synonyms',
        size: 120,
        Cell: ({ row }) => (
            <div>
                {row.original.synonyms.map((synonym, index) => (
                    <div key={index}>{synonym}</div>
                ))}
            </div>
        ),
    },
];
