import { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography, CircularProgress, IconButton,  ButtonGroup} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MaterialReactTable from 'material-react-table';
import Select from 'react-select'; 
import { Nfdi4energyAnnotatorDescription } from './Nfdi4energyAnnotatorDescription';
import { HighlightedLabel, InputContainer, HelperTextContainer, HelperText, AnnotatedText, ScrollableText, ButtonContainer } from './styledComponent';
import { generateLightColor } from './utils';
import { createColumns } from './columns';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const Annotator = () => {
    const [inputText, setInputText] = useState('');
    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [annotatedText, setAnnotatedText] = useState('');
    const [selectedOntologies, setSelectedOntologies] = useState([]); 
    const [termColors, setTermColors] = useState({});

    const [ontologyOptions, setOntologyOptions] = useState([]);

    useEffect(() => {
        const fetchOntologyList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_NFDI4ENERGYANNOTATOR_BACKEND_URL}/ontology/list`);
                const data = await response.json();
                const formattedOptions = data.ontologies
                .filter(ontology => ontology.ontologyId !== "dr")
                .map(ontology => ({
                    value: ontology.ontologyId,
                    label: `${ontology.title} (${ontology.ontologyId})`
                }));
                setOntologyOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetching ontology IDs:", error);
            }
        };

        fetchOntologyList();
    }, []);

    const handleInputChange = (e) => setInputText(e.target.value);

    const handleAnnotate = async () => {
        if (!inputText.trim() || selectedOntologies.length === 0) return;

        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_NFDI4ENERGYANNOTATOR_BACKEND_URL}/annotate`, { 
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text: inputText, ontology_ids: selectedOntologies.map(o => o.value) }),
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            setMatches(data.matches);
            setAnnotatedText(inputText);

            const colorMap = {};
            data.matches.forEach((match) => {
                if (!colorMap[match.matched_term]) {
                    colorMap[match.matched_term] = generateLightColor();
                }
            });
            setTermColors(colorMap);
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while annotating the text. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleReset = () => {
        setInputText('');
        setMatches([]);
        setAnnotatedText('');
        setSelectedOntologies([]);
    };

    const highlightText = () => {
        if (!annotatedText || matches.length === 0) return annotatedText;

        let parts = [];
        let lastIndex = 0;
        const sortedMatches = [...matches].sort((a, b) => (a.start === b.start ? (b.end - b.start) - (a.end - a.start) : a.start - b.start));

        sortedMatches.forEach((match, index) => {
            if (match.start >= lastIndex) {
                parts.push(annotatedText.slice(lastIndex, match.start));
                parts.push(
                    <HighlightedLabel key={`label_${index}`} color={termColors[match.matched_term]}>
                        {annotatedText.slice(match.start, match.end)}
                    </HighlightedLabel>
                );
                lastIndex = match.end;
            }
        });

        parts.push(annotatedText.slice(lastIndex));
        return parts;
    };

    const isAnnotateDisabled = !inputText.trim() || selectedOntologies.length === 0;
    const isResetDisabled = inputText.trim().length === 0 && selectedOntologies.length === 0;

    const getUniqueMatches = () => {
        const seen = new Set();
        return matches.filter((match) => {
            if (seen.has(match.matched_term)) {
                return false;
            }
            seen.add(match.matched_term);
            return true;
        });
    };

    // CSV Headers and Data
    const csvHeaders = [
        { label: 'Matched Term', key: 'matched_term' },
        { label: 'Ontology', key: 'ontologyId' },
        { label: 'Synonyms', key: 'synonyms'},
        { label: 'IRI', key: 'iri' },
        { label: 'Score', key: 'score'},
        { label: 'Start', key: 'start' },
        { label: 'End', key: 'end' }
    ];
    const csvData = getUniqueMatches().map(item => ({
        ...item,
        synonyms: item.synonyms ? item.synonyms.join(', ') : '' 
    }));

    // Export to Excel
    const exportToExcel = () => {
        const excelData = csvData;
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Annotations');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'annotations.xlsx');
    };


    return (
        <Grid container justifyContent="center">
            <Grid item md={11}>
                <Nfdi4energyAnnotatorDescription />
                <Typography variant="h6">Select Ontologies</Typography>
                <Select
                    isMulti
                    options={ontologyOptions}
                    value={selectedOntologies}
                    onChange={setSelectedOntologies}
                    placeholder="Choose ontologies..."
                />
                {!annotatedText ? (
                    <InputContainer>
                    <Typography variant="h6">Text To Annotate</Typography>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={6}
                        fullWidth
                        value={inputText}
                        onChange={handleInputChange}
                        helperText={
                            <HelperTextContainer>
                                <HelperText>
                                    <b>Example Text:</b> <i>electrical energy is a form of energy derived from the potential or kinetic energy of charged particles.</i>
                                </HelperText>
                                <IconButton onClick={() => setInputText('electrical energy is a form of energy derived from the potential or kinetic energy of charged particles.')}>
                                    <FileCopyOutlinedIcon />
                                </IconButton>
                            </HelperTextContainer>
                        }
                    />
                    </InputContainer> ) : (
                    <AnnotatedText>
                        <Typography variant="h6">Annotated Text</Typography>
                        <ScrollableText>{highlightText()}</ScrollableText>
                    </AnnotatedText>)}

                <ButtonContainer>
                    <Button variant="contained" color="primary" onClick={handleAnnotate} disabled={isAnnotateDisabled}>
                        Annotate
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleReset} disabled={isResetDisabled}>
                        Reset
                    </Button>
                </ButtonContainer>
                {isLoading ? <CircularProgress /> : 
                    <MaterialReactTable
                            columns={createColumns(inputText)}
                            data={getUniqueMatches()}
                            initialState={{ pagination: { pageSize: 10 }, density: 'compact' }}
                            renderTopToolbarCustomActions={() => (<Typography variant="h6">Matched Terms</Typography>)}
                            renderBottomToolbarCustomActions={() => (
                                <>
                                    <ButtonGroup variant="contained" size="small" aria-label="Basic button group">
                                        <Button variant="contained" color="primary" disabled={csvData.length === 0} style={{  opacity: csvData.length === 0 ? 0.5 : 1 }}>
                                            <CSVLink data={csvData} headers={csvHeaders} filename="annotations.csv" style={{ color: "white", textDecoration: "none" }}>
                                                Download CSV
                                            </CSVLink>
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={exportToExcel} disabled={csvData.length === 0} style={{ opacity: csvData.length === 0 ? 0.5 : 1 }}>
                                            Download Excel
                                        </Button>
                                    </ButtonGroup>
                                </>
                            )}
                    />
                }
            </Grid>
        </Grid>
    );
};
