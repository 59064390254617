import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled('div')({
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: 'auto',
});

const HighlightBox = styled('div')({
    margin: '10px 0',
    padding: '10px',
    backgroundColor: '#fff3cd',
    borderLeft: '5px solid #ffc107',
    borderRadius: '4px',
    lineHeight: '1.5',
});

export const AnnotatorUsageGuide = () => {
    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                How to Use the NFDI4Energy Annotator
            </Typography>

            <Typography variant="body1" paragraph>
                The NFDI4Energy Annotator allows users to recognize OEO terms in text. It interacts with an external API to fetch annotations.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Steps to Use:
            </Typography>

            <HighlightBox>
                <List>
                    <ListItem>
                        <ListItemText primary="1. Enter Text" secondary="Type or paste text into the input box. You can also use the copy icon to insert an example text." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="2. Click 'Annotate'" secondary="Clicking the 'Annotate' button sends the text to the backend for processing and highlights recognized terms." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="3. View Highlighted Terms" secondary="The annotated text is displayed with highlights for recognized terms. Hover over a term to see it emphasized." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="4. Check Matched Terms Table" secondary="Below the annotated text, a table lists the unique matched terms with relevant details." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="5. Reset and Try Again" secondary="Use the 'Reset' button to clear the input and start fresh." />
                    </ListItem>
                </List>
            </HighlightBox>

            <Typography variant="h6" gutterBottom>
                Backend API Documentation
            </Typography>
            <Typography variant="body1" paragraph>
                The Annotator component utilizes the following backend API endpoint for text annotation:
                <br />
                <a href="https://service.tib.eu/sandbox/nfdi4energyannotator/docs#/" target="_blank" rel="noopener noreferrer">
                    NFDI4Energy Annotator API - Swagger Documentation
                </a>
            </Typography>
        </Container>
    );
};