// utils.js
export const generateLightColor = () => {
  const r = Math.floor(Math.random() * 156 + 150); // 100-255 range for light colors
  const g = Math.floor(Math.random() * 156 + 150);
  const b = Math.floor(Math.random() * 156 + 150);
  return `rgb(${r}, ${g}, ${b})`;
};

export const encodeIRI = (iri) => encodeURIComponent(iri);

export const generateLink = (iri, ontologyId) => 
  `https://terminology.tib.eu/ts/ontologies/${ontologyId}/terms?iri=${encodeIRI(iri)}&obsoletes=false`;
