import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { useState, useRef, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[300],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export const SandboxFooter = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  const mainRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  }, [isExpanded]);

  return (
    <div className="row">
      <Tooltip title={isExpanded ? 'Click to close footer' : 'Click to open footer'}>
        <div className={classes.footer} onClick={toggleExpand}>
          <div>{isExpanded ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}</div>
        </div>
      </Tooltip>
      <div ref={mainRef} className={`collapse-panel ${isExpanded ? 'expanded' : ''}`}>
        <div className="row site-footer">
          <div className="col-sm-4 footer-col">
            <a href="https://www.dfg.de/en/index.jsp" target="_blank" rel="noopener noreferrer">
              <img src="/dfg_logo.png" alt="" className="footer-logo" />
            </a>
            <ul className="footer-list">
              <li>
                <small>NFDI4ING is funded by DFG</small>
              </li>
              <li>
                <small>Project Number 442146713</small>
              </li>
            </ul>
          </div>
          <div className="col-sm-2">
            <h6>ABOUT</h6>
            <hr className="me-5" />
            <div className='row'>
              <div className='col-sm-6'>
                <ul className="footer-list">
                  <li>
                    <Link to={process.env.REACT_APP_PROJECT_SUB_PATH + '/PrivacyPolicy'} className="footer-link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.REACT_APP_PROJECT_SUB_PATH + '/TermsOfUse'} className="footer-link">
                      Terms of use
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.REACT_APP_PROJECT_SUB_PATH + '/imprint'} className="footer-link">
                      Imprint
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='col-sm-6'>
                <ul className="footer-list">
                  <li>
                    <Link to={process.env.REACT_APP_PROJECT_SUB_PATH + '/contact'} className="footer-link">
                      Contact us
                    </Link>
                  </li>
                    <li>
                      <small>
                        Free SVG Background by{' '}
                        <a target="_blank" href="https://bgjar.com">
                          BGJar
                        </a>
                      </small>
                    </li>
                </ul>
              </div>

            </div>


          </div>
          <div className="col-sm-2">
            <h6>COMMUNITY</h6>
            <hr className="me-5" />
            <a href="https://nfdi4ing.de/" target="_blank" rel="noopener noreferrer">
              <img src="/nfdi4ing_logo.png" alt="Logo NFDI4ING" className="footer-funding-logo" />
            </a>
          </div>
          <div className="col-sm-4">
            <h6>PROVIDED BY</h6>
            <hr className="me-5" />
            <a href="https://www.tib.eu/en/" target="_blank" rel="noopener noreferrer">
              <img src="/TIB_Logo_EN_WM_B.SVG" alt="Logo Technische Informationsbibliothek (TIB)" className="footer-logo" />
            </a>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
