import { Typography } from '@mui/material'

export const Nfdi4energyAnnotatorDescription = () => {
  return (
    <div>                
      <Typography variant="h5" gutterBottom textAlign="center">
          NFDI4Energy Annotator
      </Typography>
      <Typography variant="body2" gutterBottom>
          This annotator utilizes terms from all the ontologies in NFDI4Energy domain to match input text. We generate n-grams (unigrams, bigrams, trigrams, Quadgrams, Pentagrams) from the text and perform matching with a confidence score greater than 90%.
      </Typography>
      <Typography variant="body2" gutterBottom>
          <b>Future Work:</b> Our future plans include enhancing this service with semantic annotation capabilities using Sentence-BERT (SBERT), as well as integrating additional descriptive metadata.
      </Typography>
</div>
  )
}
