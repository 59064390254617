import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { Typography, Card, CardContent, CircularProgress, Button, Skeleton } from '@mui/material';
import { ResultSection } from './ResultSection';
import { Introduction } from './Introduction';
import Scrollbars from 'react-custom-scrollbars-2';

const SearchInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 14% 0 14%;
`;

const TextInput = styled.input`
  width: 100%;
  border: 1px solid #4f6d7a;
  height: 40px;
  padding-left: 10px;
  outline: none;
  font-size: 20px;
`;

const SearchButton = styled.button`
  background: #4f6d7a;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.button`
  font-family: sans-serif;
  width: auto;
  flex-grow: 1;
  height: auto;
  font-size: 1rem;
  text-align: center;
  margin: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid grey;
  background: ${(props) => props.background};
  cursor: pointer;

  &:hover {
    background: #ccc;
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ModelResultDisplay = ({ modelApi }) => {
  const [labels1, setLabels1] = useState([]);
  const [labels2, setLabels2] = useState([]);
  const [labels4, setLabels3] = useState([]);
  const [inputWords, setInputWords] = useState('');
  const [shouldFetch, setShouldFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (shouldFetch) {
      fetchResults(inputWords);
    }
  }, [inputWords, shouldFetch]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      clearResults();
      setShouldFetch(true);
      setHasSearched(true);
    } else if (event.key === 'Escape') {
      clearInput();
      clearResults();
    }
  };

  const fetchResults = (input) => {
    setLoading(true);
    const endpoints = ['nfdi4chem', 'nfdi4ing', 'nfdi4energy'];
    const fetchPromises = endpoints.map((endpoint) => fetch(`${modelApi}/${endpoint}?query=${input}`, { method: 'GET', mode: 'cors' }));

    Promise.all(fetchPromises)
      .then((responses) => {
        return Promise.all(
          responses.map((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
        );
      })
      .then((data) => {
        setLabels1(data[0].similar_labels || []);
        setLabels2(data[1].similar_labels || []);
        setLabels3(data[2].similar_labels || []);
      })
      .catch((error) => {
        alert(`Error fetching similar labels: ${error.message}`);
        clearResults();
      })
      .finally(() => {
        setLoading(false);
        setShouldFetch(false);
      });
  };

  const clearResults = () => {
    setLabels1([]);
    setLabels2([]);
    setLabels3([]);
    setSearchResults([]);
    setHasSearched(false);
  };

  const clearInput = () => {
    setInputWords('');
    setHasSearched(false);
  };

  const handleBoxClick = async (termLabels) => {
    setLoading(true);
    try {
      const response = await fetch(`https://service.tib.eu/ts4tib/api/search?q=${termLabels}&rows=5`);
      const data = await response.json();
      setSearchResults(data.response.docs);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderSearchResults = (words) => {
    return words.map((item, index) => {
      const lightness = 60 - index * 0.5;
      const background = `rgba(79, 109, 122, ${lightness / 100})`;
      return (
        <Box key={`box_${index}`} style={{ background, padding: '10px', cursor: 'pointer' }} onClick={() => handleBoxClick(item)}>
          {item}
        </Box>
      );
    });
  };

  const getUrl = (result) => {
    switch (result.type) {
      case 'class':
        return `https://terminology.tib.eu/ts/ontologies/${result.ontology_name}/terms?iri=${encodeURIComponent(result.iri)}&obsoletes=false`;
      case 'property':
        return `https://terminology.tib.eu/ts/ontologies/${result.ontology_name}/props?iri=${encodeURIComponent(result.iri)}&obsoletes=false`;
      default:
        return `https://terminology.tib.eu/ts/ontologies/${result.ontology_name}/individuals?iri=${encodeURIComponent(result.iri)}&obsoletes=false`;
    }
  };

  const truncateDescription = (descriptions, wordLimit) => {
    if (!Array.isArray(descriptions)) {
      return '';
    }
    const concatenatedText = descriptions.join(' ');
    const words = concatenatedText.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return concatenatedText;
  };

  return (
    <div
      style={{
        height: '75vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Introduction />
      <SearchInput>
        <TextInput type="text" value={inputWords} onChange={(event) => setInputWords(event.target.value)} onKeyDown={handleKeyPress} placeholder="For example: device for measuring temperature" />
        <SearchButton onClick={() => handleKeyPress({ key: 'Enter' })}>
          <SearchIcon style={{ height: '30px', color: 'white' }} />
        </SearchButton>
      </SearchInput>
      <div style={{ display: 'flex', flexDirection: 'row', height: '35vh', width: '100%', paddingTop: '2%' }}>
        <ResultSection domain="Nfdi4Chem" isLoading={loading} words={labels1} label={inputWords} hasSearched={hasSearched} renderResults={renderSearchResults} />
        <ResultSection domain="Nfdi4Ing" isLoading={loading} words={labels2} label={inputWords} hasSearched={hasSearched} renderResults={renderSearchResults} />
        <ResultSection domain="Nfdi4Energy" isLoading={loading} words={labels4} label={inputWords} hasSearched={hasSearched} renderResults={renderSearchResults} />
      </div>
      <ScrollContainer>
        <Scrollbars style={{ width: '100%', height: '36vh', overflowY: 'hidden' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content' }}>
            {loading ? (
              <CircularProgress />
            ) : !hasSearched || searchResults.length === 0 ? (
              <SkeletonContainer>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Skeleton key={index} variant="rectangular" width={400} height={350} />
                ))}
              </SkeletonContainer>
            ) : (
              searchResults.map((result, index) => (
                <Card key={index} style={{ margin: '10px', width: '350px', minHeight: '330px', border: '1px solid grey' }}>
                  <CardContent style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Typography variant="body2">
                      <b>label:</b> {result.label}
                    </Typography>
                    <Typography variant="body2">
                      <b>description:</b> {truncateDescription(result.description, 40)}
                    </Typography>
                    <Typography variant="body2">
                      <b>iri:</b> {result.iri}
                    </Typography>
                    <div style={{ justifySelf: 'flex-end', marginTop: 'auto' }}>
                      <Button size="small" variant="outlined">
                        {result.ontology_name}
                      </Button>
                      <Button size="small" variant="outlined">
                        {result.type}
                      </Button>
                      <Button size="small" variant="contained" href={getUrl(result)} target="_blank">
                        learn more
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              ))
            )}
          </div>
        </Scrollbars>
      </ScrollContainer>
    </div>
  );
};
