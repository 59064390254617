import { Grid, Hidden, Typography } from '@mui/material';
import { MiniDrawer } from './MiniDrawer';
import CoypuLogo from '../mappings/searchMappings/CoypuLogo';
import sandbox_2 from '../..//../assets/img/sandbox_logos/sandbox_2.svg';
import { RateMe } from './buttons/RateMe';
import { FileIssue } from './buttons/FileIssue';
import { ReleaseInfo } from './buttons/ReleaseInfo';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import { Stars } from './buttons/Stars';
import { Nfdi4ingLogo } from '../webprotege/Nfdi4ingLogo';
import HowToUse from './buttons/HowToUse';
import { FairDSLogo } from '../word2vec/FairDSLogo';
import Nfdi4EnergyLogo from "../pid/Nfdi4EnergyLogo";

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '12%',
  },
}));

const pathsWithoutButtons = ['/ts/sandbox/release', '/ts/sandbox/funding', '/ts/sandbox/subject_indexer'];

const SandboxLayout = ({ children }) => {
  const location = useLocation();
  const classes = useStyles();

  const hideButtons = pathsWithoutButtons.includes(location.pathname);
  const showSpecificButtons = location.pathname === '/ts/sandbox';

  const OnDet = location.pathname === '/ts/sandbox/ondet' || location.pathname === '/ts/sandbox/ondet/add';
  const mapping = location.pathname === '/ts/sandbox/mappingTableView' || location.pathname === '/ts/sandbox/mappingGraphView' || location.pathname === '/ts/sandbox/generatemapping';
  const annotation = location.pathname === '/ts/sandbox/annotation';
  const nfdi4energyAnnotator = location.pathname === '/ts/sandbox/nfdi4energyAnnotator';
  const pidChecker = location.pathname === '/ts/sandbox/pidChecker'
  const gitOntologyStatusCheck = location.pathname === '/ts/sandbox/gitOntologyStatusCheck'
  const gitQualityassessment = location.pathname === '/ts/sandbox/qualityassessment'
  const webprotege = location.pathname === '/ts/sandbox/webprotege';
  const semanticSearch = location.pathname === '/ts/sandbox/semanticsearch';

  let url = '';
  let extension = '';

  if (OnDet) {
    extension = 'OnDet';
    url = 'https://docs.google.com/forms/d/1vVI1B1T66aYpOg_0LTEyN9JKv35paEW5udNMzaZ66HY/viewform';
  } else if (mapping) {
    extension = 'mappings';
    url = 'https://docs.google.com/forms/d/1rPfhm_Dot-E3LsZW-8vRpQlD9wqnm8bUIgM5sPT2Nvg/viewform';
  } else if (annotation) {
    extension = 'annotation';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSfuHyQ-c474MEkNDmV9n5kgUXgVFxklr4Em7iVAeekm7_ZBtg/viewform';
  } else if (nfdi4energyAnnotator) {
    extension = 'nfdi4energyAnnotator';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSes0X2R4LnD8peZTzobMvVsTOrO8AHCaYcL36cF_wfX0XtssA/viewform';
  } else if (pidChecker) {
    extension = 'pidChecker';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSeb2FeURPX4ZF2p8nm6h50o1LuOUA001VM_kX-sHPio7ePb6w/viewform';
  } else if (gitOntologyStatusCheck) {
    extension = 'gitOntologyStatusCheck';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSevQNITzPuwFIPcPaPTIppbx13__3BCaEunaOh5NUibdTqE7A/viewform';
  } else if (gitQualityassessment) {
    extension = 'gitQualityassessment';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSeqRa8u7MgJiGiuswVCvn6jSg8MyXtDqswc9JnHOAv7waYmpg/viewform';
  } else if (webprotege) {
    extension = 'webprotege';
    url = 'https://docs.google.com/forms/d/1aF-qUfEIt008zKSmoU-M-fgMNc0LBFkMXuBFUrvMfaM/viewform';
  } else if (semanticSearch) {
    extension = 'semanticSearch';
    url = 'https://docs.google.com/forms/d/1hLAxQfzufmSBYUBrsna7Yv-7BkIM0FPWBAcfneJkogE/viewform';
  } else  {
    extension = 'pairwiseSimilarity';
    url = 'https://docs.google.com/forms/d/1aF-qUfEIt008zKSmoU-M-fgMNc0LBFkMXuBFUrvMfaM/viewform';
  }

  return (
    <div style={{ display: 'flex' }}>
      <Hidden smUp>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            minHeight: '79.5vh',
            marginTop: '1rem',
            padding: '2rem',
          }}
        >
          <Typography variant="h5" color="textSecondary">
            This page is not available in mobile version.
          </Typography>
        </div>
      </Hidden>
      <Hidden smDown>
        <MiniDrawer />
        <Grid container>
          <Grid
            item
            md={10.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: location.pathname === '/ts/sandbox' || location.pathname === '/ts/sandbox/funding' ? 'center' : null,
              alignItems: 'center',
              minHeight: '80vh',
              padding: location.pathname === '/ts/sandbox/webprotege' || location.pathname === '/ts/sandbox/semanticSearch' ? 0 : '2rem',
            }}
          >
            {children}
          </Grid>
          <Grid item md={1.5} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, gap: '5px' }}>
              {showSpecificButtons ? <img src={sandbox_2} alt="erstellen" className={classes.image} /> : null}
              {!hideButtons && <FileIssue />}
              {!hideButtons && !showSpecificButtons && <HowToUse />}
              {!hideButtons && !showSpecificButtons && <ReleaseInfo extension={extension} />} 
              {!hideButtons && !showSpecificButtons && OnDet && <CoypuLogo />}
              {!hideButtons && !showSpecificButtons && mapping && <CoypuLogo />}
              {!hideButtons && !showSpecificButtons && annotation && <Nfdi4ingLogo />}
              {!hideButtons && !showSpecificButtons && nfdi4energyAnnotator && <Nfdi4EnergyLogo />}
              {!hideButtons && !showSpecificButtons && pidChecker && <Nfdi4EnergyLogo />}
              {!hideButtons && !showSpecificButtons && gitOntologyStatusCheck && <Nfdi4EnergyLogo />}
              {!hideButtons && !showSpecificButtons && gitQualityassessment && <Nfdi4EnergyLogo />}
              {!hideButtons && !showSpecificButtons && webprotege && <Nfdi4ingLogo />}
              {!hideButtons && !showSpecificButtons && semanticSearch && <FairDSLogo />}
            </div>
            {!hideButtons && !showSpecificButtons && url !=='' && <RateMe url={url} />}
            {OnDet && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLScIxBL4xqNM8wP1coPWHm732Jojf0mzbTBOMaUSexgPkjrV3A/formResponse?usp=pp_url" ratingEntry="805352637" tool="OnDet" />)}
            {mapping && <Stars url="https://docs.google.com/forms/d/e/1FAIpQLScgUSzCfc4Dgnn31-XY1uOQ4KEA8zfU9eGd4kKgg5ShQtsC7A/formResponse?usp=pp_url" ratingEntry="237934661" tool="Mappings" />}
            {annotation && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSfdt4lpuKBjBOfMuNpskjy2jAxvCEARvj-ZQfJxkyR3ILiGgw/formResponse?usp=pp_url" ratingEntry="832478081" tool="UAT Annotator" />)}
            {nfdi4energyAnnotator && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSfhETbWEORqWqFGOjIyZXPhPHbMrLCDl3bZo8U613S5-cEC_A/formResponse?usp=pp_url" ratingEntry="1606305440" tool="NFDI4Energy Annotator" />)}
            {pidChecker && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSe31WZV2uuph0Av1pJ7zMOvrpQ57MZZCphl2ZPW4fdyLC9FtQ/formResponse?usp=pp_url" ratingEntry="498338012" tool="PID Checker Service" />)}
            {gitOntologyStatusCheck && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSe8equT36m-EeopYjfYf0nHpUhel-9U-U4yNQb34KbUlGTs3w/formResponse?usp=pp_url" ratingEntry="469092329" tool="Git Ontology Status" />)}
            {gitQualityassessment && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSfgnZWCIvDhIzNvlHPRU_3lbRUy59dHAr_0sqycPCAnLt7zFQ/formResponse?usp=pp_url" ratingEntry="380202931" tool="Quality Assessment" />)}
            {webprotege && <Stars url="https://docs.google.com/forms/d/e/1FAIpQLSe6zQtVQ4LVZsT86AXp9n9xWt8R5s3zr5lJoNOkJojHooZN2w/formResponse?usp=pp_url" ratingEntry="1142264448" tool="WebProtege" />}
            {semanticSearch && (<Stars url="https://docs.google.com/forms/d/e/1FAIpQLSdjawdDfDmPGPQg7e04ExyvuufewP0TKYlrQ1LpYsH1HuIA7g/formResponse?usp=pp_url" ratingEntry="176951438" tool="Semantic Search" />)}
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};

export default SandboxLayout;